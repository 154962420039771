/* REMOVE SCROLLBAR  CHROME, OPERA */
#home-page .contractor-service-type-section ::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* REMOVE SCROLLBAR IE AND FIREFOX */
#home-page .contractor-service-type-section {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-color: transparent transparent; /* firefox */
}

#home-page .contractor-service-type-container .underlineServiceClicked {
  border-bottom: 2px solid currentColor;
}

#home-page .contractor-service-type-container:hover .underlineServiceHovered {
  border-bottom: 2px solid darkgray;
}

/* prevents icon bar from shrinking*/
#home-page .contractor-service-type-container .contractor-icon img {
  max-width: none;
}
