.navbar-desktop-logo-title .header-letter-spacing,
.navbar-mobile-logo-title .header-letter-spacing {
  letter-spacing: -0.07em;
}

/* shadow styles for dropdown menu container */
.navbar-content-container
  .navigation-drop-container
  .navigation-dropdown-menu-shadow {
  box-shadow: 0px 0.5rem 1rem 0px rgb(0 10 38 / 20%);
}

/* DO NO DELETE - THIS IS HOW YOU SET BLACK SCREEN OPACITY WHEN YOU OPEN A SEARCH BAR IN AIRBNB */
/* .darken-screen-hamburger-menu-clicked {
  // for IE 
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.5);
  // for real browsers
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
} */
